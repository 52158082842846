<script>
import { CarouselCard, CarouselCardItem } from "vue-carousel-card";
import "vue-carousel-card/styles/index.css";

export default {
  data() {
    return {
      imgs: [
        "imgs/films/rainbow_poster.jpg",
        "imgs/films/Pharaons_Egypte_moderne.jpg",
        "imgs/films/mountains_poster.jpg",
      ],
    };
  },
  components: {
    CarouselCard,
    CarouselCardItem,
  },
};
</script>

<template>
  <CarouselCard
    ref="carouselCardRef"
    :interval="3000"
    :autoplay="true"
    type="card"
    arrow="never"
  >
    <CarouselCardItem v-for="img in imgs" :key="img">
      <img :src="img" alt="" />
    </CarouselCardItem>
  </CarouselCard>
</template>