<script>
import axios from "axios";
export default {
  data() {
    axios
      .get(`https://dashboard.bigsisterprod.com/api/teams`)
      .then((response) => (this.team = response.data));
    axios
      .get(`https://dashboard.bigsisterprod.com/api/about/jihan`)
      .then((response) => (this.jihan = response.data));
    return {
      team: [],
      jihan: [],
    };
  },
  mounted() {
    const blocks = document.querySelectorAll(".teamCard"),
      els = document.querySelectorAll("#hover");

    blocks.forEach((block) => {
      block.addEventListener("mousemove", (e) => {
        els.forEach((el) => {
          el.style.top = e.pageY + "px";
          el.style.left = e.pageX + "px";
        });
      });
    });
  },
};
</script>

<template>
  <section class="team">
    <div class="container">
      <h1 class="title" data-aos="fade-right" data-aos-duration="1000">
        Our <br />Team
      </h1>
      <!--Jihan-->
      <router-link
        to="/jihan"
        class="teamCard"
        data-aos="fade-down"
        data-aos-duration="1000"
      >
        <div id="hover">more info...</div>
        <figure>
          <img
            src="/imgs/jihan_2.jpg"
            alt="team"
            data-aos="fade-right"
            data-aos-duration="2000"
          />
        </figure>
        <div class="teamInfo">
          <small>Founder, Producer and Director</small>
          <h1>Jihan El-Tahri</h1>
          <div>
            <p
              v-if="jihan.section_one && jihan.section_one.length > 0"
              v-html="jihan.section_one.substring(0, 230) + `...`"
            ></p>
          </div>
        </div>
      </router-link>
      <!---->

      <router-link
        :to="{ name: 'Details', params: { id: member.id } }"
        class="teamCard"
        data-aos="fade-down"
        data-aos-duration="1000"
        v-for="member in team"
        :key="member"
      >
        <div id="hover">more info...</div>

        <figure v-if="member.image && member.image.length > 0">
          <img
            :src="'https://dashboard.bigsisterprod.com/' + member.image"
            alt=""
            data-aos="fade-right"
            data-aos-duration="2000"
          />
        </figure>
        <div class="teamInfo">
          <small>{{ member.role }}</small>
          <h1>{{ member.name }}</h1>
          <div
            v-if="member.about && member.about.length > 0"
            v-html="member.about.substring(0, 250) + `...`"
          ></div>
        </div>
      </router-link>
    </div>
  </section>
</template>
