<script>
import Team from "@/components/Team";
import Slider from "@/components/Slider";
import axios from "axios";
export default {
  components: {
    Team,
    Slider,
  },
  data() {
    axios
      .get(`https://dashboard.bigsisterprod.com/api/about/general`)
      .then((response) => (this.general = response.data));
    return {
      general: [],
    };
  },
};
</script>

<template>
  <div>
    <section class="about">
      <div class="container">
        <h1 class="title" data-aos="fade-right" data-aos-duration="2000">
          Who We <br />Are
        </h1>
        <div class="topSection">
          <div class="content">
            <p
              data-aos="fade-down"
              data-aos-duration="2000"
              v-html="general.section_one"
            ></p>
          </div>
          <figure
            data-aos="fade-left"
            data-aos-duration="2000"
            style="position: relative"
          >
            <img src="imgs/logo.jpg" alt="" />
          </figure>
        </div>
        <!---->
        <div class="moreAbout">
          <div class="content">
            <Slider data-aos="fade-down" data-aos-duration="2000" />
            <p
              data-aos="fade-left"
              data-aos-duration="2000"
              v-html="general.section_two"
            ></p>
          </div>
          <div class="content">
            <figure class="films">
              <img
                src="imgs/films/mountains.jpg"
                alt=""
                data-aos="fade-down"
                data-aos-duration="2000"
              />
            </figure>
            <p
              data-aos="fade-right"
              data-aos-duration="2000"
              v-html="general.section_three"
            ></p>
          </div>
        </div>
      </div>
    </section>
    <Team />
  </div>
</template>
